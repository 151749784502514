a.button  {
  font-family: $font-family-regular;
  line-height: 14px;
  font-size: 14px;
  display: inline-block;
  padding: 19px 80px 18px 30px;
  border-radius: 25px;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #023982;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0.25);
  }

  &.yellow {
    background-color: #F0FF00;
    color: #000C50;
  }

  &.yellow:hover {
    background-color: #F6FF4C;
  }

  &.blue {
    background-color: #00193A;
    color: #FFF;
  }

  &.blue:hover {
    background-color: #002C67;
  }

  &.white {
    background-color: #FFF;
    color: #202020;
  }

  &.white:hover {
    background-color: #F3F3F3;
  }
}

a.button:hover {
  text-decoration: none;
}
