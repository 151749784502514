// Stage on Home
.stage-1 {
	@extend %text-color-white;
	@extend %pb-m;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 100vh;
	display: flex;  // dadurch ist die breite beschränkt. buhu.
	align-items: flex-end;
	min-height: 460px;
		@media (min-width: $grid-breakpoint-md) {
			min-height: 600px;
		}
		@media (min-width: $grid-breakpoint-xl) {
			min-height: 720px;
		}
	p:last-child {
		margin-bottom: 0;
	}
	button {
		@extend %mt-s;
	}
}

