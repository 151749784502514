.text-regular,
%text-regular {
	font-weight: 300;
}
.text-bold,
%text-bold {
	font-weight: 600;
}
.text-bold-italic,
%text-bold-italic {
	font-weight: 600;
	font-style: italic;
}

.text-center,
%text-center {
	text-align: center;
}
.text-left,
%text-left {
	text-align: left;
}
.text-right,
%text-right {
	text-align: right;
}

.text-xl,
%text-xl {
	font-size: 3rem;
	line-height: 1.2;
	font-weight: 600;
		@media (min-width: $grid-breakpoint-md) {
			font-size: 4.375rem;
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 7.5rem;
		}
}
.text-l,
%text-l {
	font-size: 2.125rem;
	line-height: 1.3;
	font-weight: 600;
		@media (min-width: $grid-breakpoint-md) {
			font-size: 3.5rem;
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 3.5rem;
		}
}
.text-m,
%text-m {
	font-size: 2.125rem;
	line-height: 1.4;
	font-weight: 600;
		@media (min-width: $grid-breakpoint-md) {
			font-size: 1.875rem;
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 2.125rem;
		}
}
.text-s,
%text-s {
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 600;
		@media (min-width: $grid-breakpoint-md) {
			font-size: 1.25rem;
		}
		@media (min-width: $grid-breakpoint-xl) {
			font-size: 1.375rem;
		}
}
.text-regular,
%text-regular {
	font-size: 1rem;
	line-height: 1.625;
	font-weight: 300;
}
.text-tiny,
%text-tiny {
	font-size: .75rem;
	line-height: 1.5;
	font-weight: 300;
}
.tiny-link,
%tiny-link {
	font-size: .75rem;
	line-height: 1.5;
	font-weight: 600;
}