body,
html {
  height: 100%;
  hyphens: none;
}

html {
  overflow-x: hidden;
}

body {
  background-color: $color-white;
  color: $color_blue;
}

.page {
  @include clearfix;
  background-color: $color-white;
  max-width: $max-size;
  margin: 62px auto 0;

  @media only screen and (min-width: $tablet-size) {
    margin: 74px auto 0;
  }

  @media only screen and (min-width: $desktop-size) {
    margin: 94px auto 0;
  }
}

body.sustainablecities {
  background-color: $color-white;

  .page {
    max-width: none;
    margin: 0;
    padding-top: 13px;
    padding-bottom: 52px;
    overflow: hidden;

    @media only screen and (min-width: $tablet-size) {
      margin: 0;
      padding-top: 28px;
      padding-bottom: 68px;
    }

    @media only screen and (min-width: $desktop-size) {
      margin: 0;
      padding-top: 36px;
    }
  }
}
