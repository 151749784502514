@font-face {
	font-family: 'GT Cinetype';
	font-weight: 300;
  src: url("../fonts/GT-Cinetype-Regular.eot") format("embedded-opentype"), url("../fonts/GT-Cinetype-Regular.woff") format("woff"), url("../fonts/GT-Cinetype-Regular.woff2") format("woff2"), url("../fonts/GT-Cinetype-Regular.ttf") format("truetype"); }

@font-face {
	font-family: 'GT Cinetype';
	font-weight: 600;
  src: url("../fonts/GT-Cinetype-Bold.eot") format("embedded-opentype"), url("../fonts/GT-Cinetype-Bold.woff") format("woff"), url("../fonts/GT-Cinetype-Bold.woff2") format("woff2"), url("../fonts/GT-Cinetype-Bold.ttf") format("truetype"); }

@font-face {
	font-family: 'GT Cinetype';
	font-weight: 600;
	font-style: italic;
  src: url("../fonts/GT-Cinetype-Bold-Italic.eot") format("embedded-opentype"), url("../fonts/GT-Cinetype-Bold-Italic.woff") format("woff"), url("../fonts/GT-Cinetype-Bold-Italic.woff2") format("woff2"), url("../fonts/GT-Cinetype-Bold-Italic.ttf") format("truetype"); }