.form-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 26px;

  label {
    font-size: 12px;
    line-height: 16px;
  }

  &.has-float-label {
    flex-direction: column-reverse;
  }

  .custom-checkbox {
    margin-right: 5px;
  }
}

button {
  @extend a.button;
  @extend .blue;
}

input,
textarea {
  border: 2px solid $color-blue;

  &:focus {
    border-color: $color-yellow;
    outline: 0;
  }
}