@mixin box-shadow($property) {
	-webkit-box-shadow: 	$property;
	-moz-box-shadow: 		$property;
	box-shadow: 			$property;
}
@mixin box-shadow-btn-hover {
  -webkit-box-shadow: 0px 12px 24px 0px rgba(1,50,115,0.2);
  -moz-box-shadow:    0px 12px 24px 0px rgba(1,50,115,0.2);
  box-shadow:         0px 12px 24px 0px rgba(1,50,115,0.2);
}
@mixin box-shadow-teaser-box {
  -webkit-box-shadow: 0px 12px 34px 0px rgba(1,50,115,0.2);
  -moz-box-shadow:    0px 12px 34px 0px rgba(1,50,115,0.2);
  box-shadow:         0px 12px 34px 0px rgba(1,50,115,0.2);
}


@mixin border-radius($property) {
	border-radius: 			$property;
	-moz-border-radius: 	$property;
	-webkit-border-radius: 	$property;
}

@mixin transition($property) {
  -webkit-transition:   $property .3s ease;
  -moz-transition:    $property .3s ease;
  -o-transition:      $property .3s ease;
  transition:       $property .3s ease;
}

@mixin transition-custom($property) {
  -webkit-transition:   $property;
  -moz-transition:    $property;
  -o-transition:      $property;
  transition:       $property;
}

@mixin transition-custom-2($property, $property-2) {
  -webkit-transition:   $property, $property-2;
  -moz-transition:    $property, $property-2;
  -o-transition:      $property, $property-2;
  transition:       $property, $property-2;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform:    $property;
  -ms-transform:     $property;
  -o-transform:      $property;
  transform:         $property;
}