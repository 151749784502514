
$mobile: (
        0: 0px,
        xs: 20px,
        s: 30px,
        m: 40px,
        l: 60px,
        xl: 80px,
        xxl: 100px
);

$mobile-xs: 20px;
$mobile-s: 30px;
$mobile-m: 40px;
$mobile-l: 60px;
$mobile-xl: 80px;
$mobile-xxl: 100px;

$tablet: (
        0: 0px,
        xs: 30px,
        s: 60px,
        m: 80px,
        l: 100px,
        xl: 140px,
        xxl: 160px
);

$tablet-xs: 30px;
$tablet-s: 60px;
$tablet-m: 80px;
$tablet-l: 100px;
$tablet-xl: 140px;
$tablet-xxl: 160px;

$desktop: (
        0: 0px,
        xs: 40px,
        s: 60px,
        m: 80px,
        l: 120px,
        xl: 180px,
        xxl: 240px
);

$desktop-xs: 40px;
$desktop-s: 60px;
$desktop-m: 80px;
$desktop-l: 120px;
$desktop-xl: 180px;
$desktop-xxl: 240px;

// margin and padding shorthands
$space-prefixes : (
        p  : padding,
        pt : padding-top,
        pr : padding-right,
        pb : padding-bottom,
        pl : padding-left,
        m  : margin,
        mt : margin-top,
        mr : margin-right,
        mb : margin-bottom,
        ml : margin-left,
) !default;

// change these values if its not imported into bootstrap
$breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        s: $mobile-size,
  // Medium screen / tablet
        m: $tablet-size,
  // Large screen / desktop
        l: $desktop-size,

) !default;


@mixin make-space( $prefixes, $breakpoints, $mobile, $tablet, $desktop) {
  @each $breakpoint-name, $breakpoint-value in $breakpoints {
    @if($breakpoint-value == 0) {
      @each $prefixes-short, $prefixes-long in $prefixes {
        @each $mobile-short, $mobile-long in $mobile {
          .#{$prefixes-short}-#{$mobile-short} {
            #{$prefixes-long}: #{$mobile-long};
          }
        }
      }
    }
    @else{
      @media screen and (min-width: $breakpoint-value) {
        @each $prefixes-short, $prefixes-long in $prefixes {
          @if($breakpoint-value == $tablet-size){
            @each $tablet-short, $tablet-long in $tablet {
              .#{$prefixes-short}-#{$tablet-short} {
                #{$prefixes-long}: #{$tablet-long};
              }
            }
          }
          @if($breakpoint-value == $desktop-size){
            @each $desktop-short, $desktop-long in $desktop {
              .#{$prefixes-short}-#{$desktop-short} {
                #{$prefixes-long}: #{$desktop-long};
              }
            }
          }
        }
      }
    }
  }
}

@include make-space( $space-prefixes, $breakpoints, $mobile, $tablet, $desktop);
