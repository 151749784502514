%btn {
	font-size: 0.875rem;
	line-height: 1.625;
	font-weight: 600;
	border: 0px;
	cursor: pointer;
	@include border-radius(100px); 
	@include transition(all);
}
%btn:hover {
	@include box-shadow-btn-hover; 
	@include transition(all);
}
%btn-noIcon {
	padding: .9375rem 3.5rem .9375rem 1.875rem;
}
%btn-noIcon:hover {
	padding: .9375rem 1.875rem .9375rem 3.5rem;
}
%btn-wIcon {
}

.btn-primary {
	@extend %btn;
	@extend %btn-noIcon;
	color: $secondary-color;
	background-color: $primary-color;
}
.btn-primary:hover {
	background-color: $primary-color-lighter;
}
.btn-secondary {
	@extend %btn;
	@extend %btn-noIcon;
	color: #fff;
	background-color: $secondary-color;
}
.btn-secondary:hover {
	background-color: $secondary-color-lighter;
}