.w-0 {
  width: 0;
}

.w-100 {
  width: 100%;
}

.full-width {
  width: 100%;
  display: block;
}

.p-0 {
  padding:0;
}

.position {
  &--relative {
    // scss-lint:disable ImportantRule
    position: relative !important;
  }
}

.oflow-hidden {
  overflow: hidden;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clearfix {
  display: block;
  content: '';
  clear: both;
}


.small {
  font-size: 85%;
}

.lead {
  font-size: 150%;
}

.content-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}


body {
  font-family: $font-family-regular;
  font-size: 1rem;
  color:$color-blue;
  line-height: 1.625;
}

.overflow-hidden{
  overflow: hidden;
}


section.module {
  margin-left: 5px;
  margin-right: 5px;

  @media (min-width: $tablet-size){
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: $desktop-size){
    margin-left: 25px;
    margin-right: 25px;
  }

  @media (min-width: 1600px){
    margin-left: 85px;
    margin-right: 85px;
  }

  &.fullscreen {
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: hidden;

    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.grid-indent {
  padding-left: 5px;
  padding-right: 5px;

  @media (min-width: $tablet-size){
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: $desktop-size){
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (min-width: 1600px){
    padding-left: 85px;
    padding-right: 85px;
  }
}
