// scss-lint:disable StringQuotes
$font-family-bold: 'GT Cinetype Bold', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'GT Cinetype Bold';
  src: url('../fonts/GT-Cinetype-Bold.eot') format('embedded-opentype'),
  url('../fonts/GT-Cinetype-Bold.woff') format('woff'),
  url('../fonts/GT-Cinetype-Bold.woff2') format('woff2'),
  url('../fonts/GT-Cinetype-Bold.ttf') format('truetype');
}

$font-family-regular: 'GT Cinetype Regular', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'GT Cinetype Regular';
  src: url('../fonts/GT-Cinetype-Regular.eot') format('embedded-opentype'),
  url('../fonts/GT-Cinetype-Regular.woff') format('woff'),
  url('../fonts/GT-Cinetype-Regular.woff2') format('woff2'),
  url('../fonts/GT-Cinetype-Regular.ttf') format('truetype');
}


$font-family-bold-italic: 'GT Cinetype Bold Italic', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'GT Cinetype Bold Italic';
  src: url('../fonts/GT-Cinetype-Bold-Italic.eot') format('embedded-opentype'),
  url('../fonts/GT-Cinetype-Bold-Italic.woff') format('woff'),
  url('../fonts/GT-Cinetype-Bold-Italic.woff2') format('woff2'),
  url('../fonts/GT-Cinetype-Bold-Italic.ttf') format('truetype');
}
// scss-lint:enable StringQuotes
p{
  font-family: $font-family-regular;
}
.font-size-xl, .font-size-l, .font-size-m, .font-size-s{
  font-family: $font-family-bold;
  margin-bottom: .3em;
}

.h1,
h1,
.font-size-xl{
  line-height: 80px;
  font-size: 60px;
  @media (min-width: $tablet-size){
    line-height: 90px;
    font-size: 70px;
  }
  @media (min-width: $desktop-size){
    line-height: 140px;
    font-size: 120px;
  }
}

.h2,
h2,
.font-size-l{
  line-height: 46px;
  font-size: 34px;
  @media (min-width: $tablet-size){
    line-height: 80px;
    font-size: 60px;
  }
}

.h3,
h3,
.font-size-m{
  line-height: 32px;
  font-size: 26px;
  @media (min-width: $tablet-size){
    line-height: 42px;
    font-size: 30px;
  }
  @media (min-width: $desktop-size){
    line-height: 48px;
    font-size: 34px;
  }
}

.h4,
h4,
.font-size-s{
  line-height: 30px;
  font-size: 20px;
  @media (min-width: $desktop-size){
    line-height: 36px;
    font-size: 22px;
  }
}

.h5,
h5,
.font-size-tiny{
  font-family: $font-family-regular;
  line-height: 18px;
  font-size: 12px;
}

a{
  font-family: $font-family-bold-italic;
  line-height: 26px;
  font-size: 16px;

  &:hover{
    color: #000BC3;
  }

  &.font-size-tiny{
    font-family: $font-family-bold-italic;
  }
}

.italic {
  font-family: $font-family-bold-italic;
}

.subheadline {
  padding-left: 24px;
}

p, span, a {
  margin-bottom: .3em;
}
