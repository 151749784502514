.container-fluid {
	padding: 0 $outside-gutter;
}
@media (min-width: $grid-breakpoint-sm) {
	.container-fluid {
		padding: 0 $outside-gutter-sm;
	}
}
@media (min-width: $grid-breakpoint-md) {
	.container-fluid {
		padding: 0 $outside-gutter-md;
	}
}
@media (min-width: $grid-breakpoint-lg) {
	.container-fluid {
		padding: 0 $outside-gutter-lg;
	}
}
@media (min-width: $grid-breakpoint-xl) {
	.container-fluid {
		padding: 0 $outside-gutter-xl;
	}
}
@media (min-width: $grid-breakpoint-xxl) {
	.container-fluid {
		padding: 0 $outside-gutter-xxl;
	}
}