/* IE fix -> SVG scaling */
canvas {
  display: block;
  width: 100%;
  visibility: hidden;
}

.svg-wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.svg-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
