.contact-card-img {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 1rem;
	padding-top: 75%;
		@media (min-width: $grid-breakpoint-sm) {
			padding-top: 120%;
		}
		@media (min-width: $grid-breakpoint-md) {
			padding-top: 100%;
		}
		@media (min-width: $grid-breakpoint-lg) {
			padding-top: 140%;
		}
		@media (min-width: $grid-breakpoint-xl) {
			padding-top: 120%;
		}
}

.contact-card {
	@extend %mb-l;
	p {
		margin-bottom: 0;
	}
	.contact-card-info {
		margin-top: 1rem;
	}
}

@media (min-width: $grid-breakpoint-sm) {
	.contact-card-row div:nth-child(even) {
		transform: translateY(40px);
	}
}




.contact-card a:hover {
	background-color: $primary-color;
}