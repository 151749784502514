footer {
	.row {
		padding: 2.5rem 0;
		@media (min-width: $grid-breakpoint-md) {
			padding: 3.75rem 0;
		}
	}
}




.footer-links {
	margin-top: 2rem;
		@media (min-width: $grid-breakpoint-md) {
			margin-top: 0;
		}
}
.footer-links a {
	@extend %tiny-link;
	margin: 0 1rem;
}
.footer-links a:hover {
	background-color: #fff;
}