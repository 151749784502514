// brand colors
$color-yellow:           #F0FF00;
$color-blue:             #00193A;
$color-grey:             #F3F3F3;
$color-white:            #FFFFFF;
$color-black:            #000000;
$color-blue-accent:      #000BC3;

$color-red:              #FF006d;
$color-utility-error:    #FF006d;

.font-yellow {
  color: $color-yellow;
}

.font-grey {
  color: $color-grey;
}

.font-white {
  color: $color-white;
}

.font-blue {
  color: $color-blue;
}

.font-blue-accent{
  color: $color-blue-accent;
}

.font-black {
  color: $color-black;
}

.color-bg-yellow {
  background-color: $color-yellow;
}

.color-bg-grey {
  background-color: $color-grey;
}

.color-bg-white {
  background-color: $color-white;
}

.color-bg-blue {
  background-color: $color-blue;
}

.color-bg-black {
  background-color: $color-black;
}

.gradient-yellow-white{
  background: linear-gradient(-180deg, #F0FF00 56%, #FFFFFF 56%);
}
.svg-color-white path{
  fill:$color-white;
}
.svg-color-blue path{
  fill: $color-blue;
}
.svg-color-menu-active path{
  fill: $color-blue;
}
