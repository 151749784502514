.logo-grid {
  .logo-wrapper {
    margin-left: -16.5px;
    margin-right: -16.5px;
    margin-bottom: - $mobile-m;
    padding: 0 16.5px;

    @media screen and (min-width: $tablet-size){
      margin-bottom: - $tablet-m;
    }

    @media screen and (min-width: $desktop-size){
      margin-bottom: - $desktop-m;
    }
  }

  .logo {
    width: 160px;
    margin-left: 16.5px;
    margin-right: 16.5px;
  }
}