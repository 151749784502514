.services-grid {
	.font-size-l {
		color: $color-blue;
	}

	.font-size-m {
		@media screen and (max-width: 1900px) {
			line-height: 30px;
			font-size: 20px;
		}

		@media (min-width: $desktop-size) and (max-width: 1900px){
			line-height: 36px;
			font-size: 22px;
		}
	}

	.box {
		margin-top: $mobile-s;

		@media screen and (min-width: $tablet-size) and (max-width: $tablet-size-ls) {
			&:nth-child(3n-2) {
				margin-top: 10px;
			}
			&:nth-child(3n-1) {
				margin-top: $tablet-xs;
			}
			&:nth-child(3n) {
				margin-top: $tablet-xs + $mobile-xs;
			}
		}

		@media screen and (min-width: $tablet-size-ls) {
			&:nth-child(even) {
				margin-top: $tablet-s;
			}
		}

		@media screen and (min-width: $desktop-size) {
			&:nth-child(even) {
				margin-top: $desktop-s;
			}
		}

		.box-content {
			position: relative;
			background-color: $color-blue;
			background-size: 0px;
			background-repeat: no-repeat;
			color: $color-white;
			background-position: center center;
			height: 170px;
			max-height: 170px;
			@media screen and (min-width: $tablet-size) {
				min-height: 360px;
				max-height: inherit;
			}

			&:hover {
				background-size: cover;
			}

			p {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0;
				padding: 20px;
			}
		}
	}
}
