@font-face {
  font-family: 'dh-icons';
  src: url('../fonts/dh-icons.eot');
  src: url('../fonts/dh-icons.eot?#iefix') format('eot'),
  url('../fonts/dh-icons.woff2') format('woff2'),
  url('../fonts/dh-icons.woff') format('woff'),
  url('../fonts/dh-icons.ttf') format('truetype'),
  url('../fonts/dh-icons.svg#dh-icons') format('svg');
}

@mixin icon-styles {
  font-family: dh-icons;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: '';

  @if $filename == arrow-up-left {
    $char: '\E001';
}
  @if $filename == arrow-up-right {
    $char: '\E002';
}
  @if $filename == chevron-up {
    $char: '\E003';
}
  @if $filename == close {
    $char: '\E004';
}

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-arrow-up-left {
  @include icon(arrow-up-left);
}

.icon-arrow-up-right {
  @include icon(arrow-up-right);
}

.icon-chevron-up {
  @include icon(chevron-up);
}

.icon-close {
  @include icon(close);
}

