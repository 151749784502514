.stage {
	position: relative;
	margin-bottom: 0;
}

.stage__image {
	width: 100%;
	height: auto;
}

.stage__content {
	position: absolute;
	left: 0;
	bottom: 0;

	&.white {
		color: $color-white;
	}

	&.blue {
		color: $color-blue;
	}

	.font-size-xl {
		line-height: 80px;
		font-size: 60px;
		@media (min-width: 880px) {
			line-height: 90px;
			font-size: 70px;
		}
		@media (min-width: 1240px) {
			line-height: 140px;
			font-size: 120px;
		}
	}

	.font-size-m {
		line-height: 32px;
		font-size: 26px;
		@media (min-width: 880px) {
			line-height: 42px;
			font-size: 30px;
		}
		@media (min-width: 1240px) {
			line-height: 48px;
			font-size: 34px;
		}
	}
}

.stage__cta {
	text-align: right;

	margin-top: 40px;
	@media (min-width: $tablet-size) {
		margin-top: 0;
	}
}



