.menu {
  position: fixed;
  z-index: 9998;
  bottom: 0;
  padding: 55px 20px;
  left: 0;
  width: 100%;

  // Display none alternative für transition
  height: 0;
  overflow: hidden;

  opacity: 0;
  transition: opacity .3s, height .3s .3s;

  background-color: $color-yellow;

  @media only screen and (min-width: $tablet-size) {
    padding: 0 20px;
  }

  &.open {
    height: 100%;
    opacity: 1;
    transition: opacity .3s, height 0s;

    @media only screen and (min-width: $tablet-size) {
      height: 100%;
    }

    .centered {
      transform: translateY(0);
    }
  }

  @media only screen and (min-width: $tablet-size) {
    bottom: 0;
  }

  .centered {
    transform: translateY(100px);
    transition: transform .3s;
  }

  .align-right {
    text-align: right;
  }

  .start-wrapper {
    position: relative;
  }

  .tablet-only {
    display: none;

    @media only screen and (min-width: $tablet-size) {
      display: block;
    }

    &.align-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .mobile-only {
    display: block;

    @media only screen and (min-width: $tablet-size) {
      display: none;
    }

    &.align-right {
      text-align: right;
      float: right;
    }
  }

  a:hover {
    color: $color-blue;
    text-decoration: none;
    border-bottom: 4px solid $color-blue;

    @media only screen and (min-width: $tablet-size) {
      border-bottom: 6px solid $color-blue;
    }
  }
}
