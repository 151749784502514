%logo {
	font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    -webkit-background-size: contain;
    background-size: contain;
    display: inline-block;
    width: 11.75rem;
    height: 2.5rem;
}

%logo-light,
.logo-light {
    @extend %logo;
    background: url(../assets/logo-landscape-white.svg) no-repeat;
}
%logo-dark,
.logo-dark {
    @extend %logo;
    background: url(../assets/logo-landscape-blue.svg) no-repeat;
}