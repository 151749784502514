.flash-card {
	background-color: $secondary-color;
	color: #fff;
	@extend %text-m;
	margin-top: 20px;
	position: relative;
	padding-top: 75%;
		@media (min-width: $grid-breakpoint-sm) {
			margin-top: 30px;
			padding-top: 120%;
		}
		@media (min-width: $grid-breakpoint-md) {
			padding-top: 100%;
		}
		@media (min-width: $grid-breakpoint-lg) {
			padding-top: 140%;
		}
		@media (min-width: $grid-breakpoint-xl) {
			padding-top: 120%;
		}
}
.flash-card p {
	position: absolute;
	padding: 20px;
	bottom: 0;
}

@media (min-width: $grid-breakpoint-sm) {
	.flash-card-row div:nth-child(even) {
		transform: translateY(40px);
	}
}