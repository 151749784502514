.contact-boxes {

  .contact-boxes__image {
    width: 100%;
    height: auto;
  }

  .contact-boxes__info {
    text-align:left;
  }

  .headline {
    margin-top: 20px;
  }

  .info {
    margin-top: -5px;
  }

  .phone {
    margin-top: 15px;
  }

  .mail {
    margin-top: 0;
  }

  a {
    color:inherit;
    font-family: inherit;
    text-decoration: inherit;
  }

  .contact_box {

    &:nth-child(even){
      padding-top: 0px;
    }

    @media (min-width: $tablet-size){
      text-align: left;

      &:nth-child(even){
        padding-top: $tablet-s;
      }
    }

    @media (min-width: $desktop-size){
      text-align: left;

      &:nth-child(even){
        padding-top: $desktop-s;
      }
    }
  }
}

