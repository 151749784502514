section.listing {
	margin-bottom: 0;

	.titleCol {
		font-weight: bold;

		p {
			margin-top: 12px;
		}
	}

	.listCol {
		p:last-child {
			margin-bottom: 0;
		}
	}

	.listing-divider {
		display: none;
	}
}

section.listing + section.listing {

	.listing-divider {
		display: block;

		.listing-divider-border {
			border-top: 2px solid #f3f3f3;
		}
	}
}
