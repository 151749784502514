%bg-primary,
.bg-primary {
	background-color: $primary-color;
}
%bg-secondary,
.bg-secondary {
	background-color: $secondary-color;
}
%bg-light-grey,
.bg-light-grey {
	background-color: $light-grey;
}
%bg-white,
.bg-white {
	background-color: #fff;
}

%text-color-primary,
.text-color-primary {
	color: $primary-color;
}
%text-color-secondary,
.text-color-secondary {
	color: $secondary-color;
}
%text-color-light-grey,
.text-color-light-grey {
	color: $light-grey;
}
%text-color-white,
.text-color-white {
	color: #fff;
}