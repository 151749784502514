.nav-bar {
	position:absolute;
	width:100%;
	z-index: 9999;
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		max-height: 100%;
	}
}

.nav-bar__word-logo {
	height: 20px;
	@media (min-width: 992px) {
		height: 24px;
	}
}

.nav-bar__icon-logo {
	height: 40px;
	@media (min-width: 992px) {
		height: 50px;
	}
}

.nav-bar__icon-menu {
	display: inline-block;
	cursor: pointer;
}

.nav-bar__menu {
	cursor: pointer;
}

.nav-bar__menu-bar-1,
.nav-bar__menu-bar-2,
.nav-bar__menu-bar-3 {
	width: 35px;
	height: 4px;
	background-color: #333;
	margin: 6px 0;
	transition: 0.4s;
	border-radius: 2px;
}

.nav-bar__menu.yellow div {
	background-color: $color-yellow;
}

.nav-bar__menu.white div {
	background-color: $color-white;
}

.nav-bar__menu.blue div {
	background-color: $color-blue;
}

.nav-bar__menu--active {
	.nav-bar__menu-bar-1 {
		transform: rotate(-45deg) translate(-6px, 6px);
	}

	.nav-bar__menu-bar-2 {
		opacity: 0;
	}

	.nav-bar__menu-bar-3 {
		transform: rotate(45deg) translate(-8px, -8px);
	}
}
