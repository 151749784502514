// /* aus functions */
// scss-lint:disable ImportantRule
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none {
      display: none !important;
    }

    .d#{$infix}-inline {
      display: inline !important;
    }

    .d#{$infix}-inline-block {
      display: inline-block !important;
    }

    .d#{$infix}-block {
      display: block !important;
    }

    .d#{$infix}-table {
      display: table !important;
    }

    .d#{$infix}-table-row {
      display: table-row !important;
    }

    .d#{$infix}-table-cell {
      display: table-cell !important;
    }

    .d#{$infix}-flex {
      display: flex !important;
    }

    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
}

@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

$colors: (
        yellow: #FFDA00,
        blue: #2071B5,
        grey: #4E4B48,
        black: #000000,
        white: #ffffff
);

@each $color-name, $color-value in $colors {
  .color-#{$color-name}     { color: $color-value; }
  .bg-color-#{$color-name}      { color: $color-value; }
}
