@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

// in mobile view, cols are stacked vertically and need a margin
@mixin vertical-col-margin-until($breakpoint) {
  div[class^="col"]:not(:last-child) {
    @extend .mb-l;

    @media only screen and (min-width: $breakpoint) {
      margin-bottom: 0;
    }
  }
}

@mixin outer-gutter-padding() {
  padding-left: $grid-gutter-outside-width;
  padding-right: $grid-gutter-outside-width;

  @media only screen and (min-width: $tablet-size) {
    padding-left: $grid-gutter-outside-width-md;
    padding-right: $grid-gutter-outside-width-md;
  }

  @media only screen and (min-width: $desktop-size) {
    padding-left: $grid-gutter-outside-width-xl;
    padding-right: $grid-gutter-outside-width-xl;
  }
}

@mixin outer-gutter-negative-margin() {
  margin-left: -$grid-gutter-outside-width;
  margin-right: -$grid-gutter-outside-width;

  @media only screen and (min-width: $tablet-size) {
    margin-left: -$grid-gutter-outside-width-md;
    margin-right: -$grid-gutter-outside-width-md;
  }

  @media only screen and (min-width: $desktop-size) {
    margin-left: -$grid-gutter-outside-width-xl;
    margin-right: -$grid-gutter-outside-width-xl;
  }
}
