.reference-teaser{

  .row{
    img{
      width:100%;
    }
    .teaser{
      max-width: 100%;
      .teaser-arrow{
        position: absolute;
        color: $color-yellow;
        font-size: 18px;
        width: 28px;
        height: 28px;
        max-width: 70%;
      }
      p{
        margin-bottom: 0;
        color: $color-blue;
      }

    }
    .button-wrapper{
      text-align: center;
      position: relative;
      @media (min-width: $tablet-size){
        text-align: left;
      }
      .button{
        margin-top:40px;
        position: relative;
        @media (min-width: $tablet-size){
          margin-top:0;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  .row-eq-height {
    display: inline-block;
    @media (min-width: $tablet-size){
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display:         flex;
    }
  }
}

.img-right {
  .teaser{
    position: relative;
    box-shadow: 0 12px 34px 0 rgba(14, 0, 40, 0.24);
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -10px;

    &:hover .teaser-arrow{
      top: -34px;
      left: -7px;
      @media (min-width: $tablet-size){
        left: -34px;
        top:0px;
      }
    }

    @media (min-width: $tablet-size){
      position: absolute;
      bottom: -10px;
      right: -10px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .teaser-arrow{
      left: 0px;
      top: -27px;
      transition: right 0.3s, top 0.3s;

      @media (min-width: $tablet-size){
        left: -27px;
        top: 7px;
        transition: left 0.3s, top 0.3s;
      }
    }
  }
}

.img-left {
  .teaser{
    position: relative;
    box-shadow: 0 12px 34px 0 rgba(14, 0, 40, 0.24);
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -10px;

    &:hover .teaser-arrow{
      top: -34px;
      right: -7px;
      @media (min-width: $tablet-size){
        right: -44px;
        top:0px;
      }
    }

    @media (min-width: $tablet-size){
      position: absolute;
      bottom: -10px;
      left: -10px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .teaser-arrow{
      right: 0px;
      top: -27px;
      transition: right 0.3s, top 0.3s;

      @media (min-width: $tablet-size){
        right: -37px;
        top: 7px;
        transition: right 0.3s, top 0.3s;

      }
    }
  }
}
