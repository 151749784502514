.footer{
  background-color: $color-yellow;
  .logo-wrapper{
    text-align: center;
    padding-top:10px;
    @media only screen and (min-width: $tablet-size){
      text-align: left;
      padding-top:0;
    }
    .logo{
      height:50px;
    }
  }
  ul{
    text-align: center;
    list-style: none;
    margin:0px;
    padding: 0px;
    @media only screen and (min-width: $tablet-size){
      text-align: right;
    }
    li{
      position: relative;
      display:inline-block;
      a{
        position: relative;
        display: block;
        padding: 40px 20px 10px 20px;
        color: $color-black;
        &:hover {
          text-decoration: none;
          color: $color-black;
        }
        @media only screen and (min-width: $tablet-size){
          padding: 16px 0 16px 20px;
        }
      }

    }
  }
}
.subfooter{
  background-color: $color-blue;
  display:block;
  text-align: center;
  @media only screen and (min-width: $tablet-size){
    display:none;
  }
  a{
    display: block;
    color: $color-white;
    padding:11px;
    font-size:28px;
  }
}
