.big-teaser-left,
.big-teaser-right {
	position: relative;
	display: block;
}
.big-teaser-img {
	padding-bottom: calc(48% + 40px);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	margin-left: -20px;
	margin-right: -20px;
		@media (min-width: $grid-breakpoint-md) {
			padding-bottom: 48%;
			margin-left: 0;
			margin-right: 0;			
		}
}




.teaser-box {
	@extend %pt-xs;
	@extend %pr-xs;
	@extend %pb-xs;
	@extend %pl-xs;
	@include box-shadow-teaser-box;
	@include transition(all);
	background-color: $primary-color;
	position: relative;
	margin-top: -20px;
	width: 100%;
	max-width: 28rem;
  	font-style: normal;
  	font-weight: inherit;
	p:last-child {
		margin-bottom: 0;
	}
		@media (min-width: $grid-breakpoint-md) {
			position: absolute;
			bottom: -1rem;
			width: 40%;
			min-width: 28rem;
		}
}

@media (min-width: $grid-breakpoint-md) {
	.big-teaser-left .teaser-box {
		left: -1rem;
	}
	.big-teaser-right .teaser-box {
		right: -1rem;
	}
}








.big-teaser-left .teaser-box:before {
	display: block;
	content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='48px' height='48px'><polygon fill='#F0FF00' fill-rule='nonzero' points='25.1715729 20 8 20 8 16 32 16 32 40 28 40 28 22.8284271 3.41421356 47.4142136 0.585786438 44.5857864'></polygon></svg>");
	position: absolute;
	top: -64px;
	right: 0;
		@media (min-width: $grid-breakpoint-md) {
			top: 0;
			right: -64px;
		}
}

.big-teaser-right .teaser-box:before {
	display: block;
	content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='48px' height='48px'><polygon fill='#F0FF00' fill-rule='nonzero' points='25.1715729 20 8 20 8 16 32 16 32 40 28 40 28 22.8284271 3.41421356 47.4142136 0.585786438 44.5857864'></polygon></svg>");
	position: absolute;
	top: -64px;
	right: 0;
		@media (min-width: $grid-breakpoint-md) {
			content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='48px' height='48px'><polygon fill='#F0FF00' fill-rule='nonzero' points='22.8284271 20 47.4142136 44.5857864 44.5857864 47.4142136 20 22.8284271 20 40 16 40 16 16 40 16 40 20'></polygon></svg>");
			top: 0;
			left: -64px;
		}
}






.big-teaser-left .teaser-box:before,
.big-teaser-right .teaser-box:before {
	@include transition(transform); 
}
.big-teaser-left:hover .teaser-box:before {
	transform: translate(16px, -16px);
	@include transition(transform); 
}
.big-teaser-right:hover .teaser-box:before {
	transform: translate(16px, -16px);
	@media (min-width: $grid-breakpoint-md) {
		transform: translate(-16px, -16px);
	}
	@include transition(transform); 
}







