.contact-box {
  .image img {
    width: 100%;
  }

  .box-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(0, - $mobile-xs);

    @media screen and (min-width: $desktop-size){
      transform: translate(- $tablet-s, 0);
    }
  }

  .box {
    background-color: white;
    box-shadow: 0 12px 34px 0 rgba(14,0,40,0.24);
    margin: 0 $mobile-xs;

    @media screen and (min-width: $desktop-size){
      margin: 0;
    }
  }

  @media screen and (min-width: $tablet-size){
    position: relative;

    .box-wrapper {
      position: absolute;
      right: 0;
    }


  }

  .step-2 {
    display: none;
    position: relative;

    .close {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
}