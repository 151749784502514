@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
	font-family: $font-family;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.625;
	color: $secondary-color;
}

a, a:hover {
	color: $secondary-color;
  text-decoration: none;
  font-style: italic;
  font-weight: 600;
  @include transition(all); 
}

%hover-skew {
  @include transform(skewX(-15deg));
  transform-origin: bottom left;
}

.overflow-hidden {
  overflow: hidden;
}








