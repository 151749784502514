section {
  @extend .mb-xxl;
  @include outer-gutter-padding();
  position: relative;

  //@include vertical-col-margin-until($tablet-size);// not final

  &.colorbox {
    @extend .pt-xl;
    @extend .pb-xl;
  }

  // Ignore Section padding to fill fullwidthwith color
  .background-full-width {
    @include outer-gutter-negative-margin();
    @include outer-gutter-padding();
  }
}

img {
  max-width: 100%;
  display: block;
}

.text-middle,
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

ul, ol {
  margin: 0 0 1em;
  //padding: 0 0 0 2.5em;//default
  li{
    margin: .75em 0;
  }
}

table.basic {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;

  tr {
    vertical-align: top;
  }

  td, th {
    padding: 10px 12px;
  }

  // col-width
  $col-counter: 2;
  @while $col-counter <= 12 {
    &.cols-#{$col-counter} {
      td, th {
        width: calc( 100% / #{$col-counter} );
      }
    }
    $col-counter: $col-counter + 1;
  }

  // col-alignment
  $col-counter: 1;
  @while $col-counter <= 12 {
    &.col-#{$col-counter}-center {
      td:nth-child(#{$col-counter}),
      th:nth-child(#{$col-counter}) {
        text-align: center;
      }
    }

    &.col-#{$col-counter}-right {
      td:nth-child(#{$col-counter}),
      th:nth-child(#{$col-counter}) {
        text-align: right;
      }
    }

    $col-counter: $col-counter + 1;
  }

  td {
    background-color: rgba(0,0,0,0.05);
  }

  th {
    text-align: left;
  }
}
