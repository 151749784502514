.employee-grid {

	.leftCol {
		@extend .mb-l;

		@media (min-width: $tablet-size){
			margin-bottom: 0;
		}
	}

	.employee-element {
		text-align: center;
		position: relative;

		.text,
		.number {
			color: $color-yellow;
			font-size: 140px;
			line-height: 140px;
			width: 100%;

			@media (min-width: $tablet-size){
				font-size: 110px;
				line-height: 110px;
			}

			@media (min-width: $desktop-size){
				font-size: 140px;
				line-height: 140px;
			}

			@media (min-width: 1200px){
				font-size: 180px;
				line-height: 180px;
			}

			@media (min-width: 1440px){
				font-size: 240px;
				line-height: 240px;
			}
		}

		.text {
			position: absolute;
			color: $color-black;
			font-size: 25px;

			@media (min-width: $tablet-size){
				font-size: 25px;
			}

			@media (min-width: $desktop-size){
				font-size: 25px;
			}
			@media (min-width: 1200px){
				font-size: 28px;
			}

			@media (min-width: 1440px){
				font-size: 34px;
			}
		}

		.number {
			font-weight: bold;
		}

		&:last-child {
			margin-bottom: 0;
		}

		img {
			display: block;
			width: 100%;
		}
	}
}
