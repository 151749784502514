.intro.module {

	&.blue {
		background-color: $color-blue;
	}

	&.yellow {
		background-color: $color-yellow;
	}

	&.grey {
		background-color: $color-grey;
	}

	.grid-indent {
		width: 100%;
	}
}

.intro__teaser-first {
	margin-top: -40px;
}

.intro__teaser {
	position: relative;
	width: 100%;

	img {
		width: 100%;
	}

	.intro__teaser__info {
		position: absolute;
		bottom: -10px;
		left: -10px;
		background-color: $color-yellow;
		box-shadow: 0 12px 34px 0 rgba(14, 0, 40, 0.24);
		padding: 20px;
		max-width: 280px;
		text-decoration: none;
	}

	.intro__teaser__info:hover {
		color: inherit;

		.intro__teaser__arrow {
			right: -43px;
			top: -7px;
			transition: right 0.3s, top 0.3s;
		}
	}

	.intro__teaser__arrow {
		display: block;
		width: 28px;
		height: 28px;
		position: absolute;
		right: -37px;
		top: 0px;
		color: $color-yellow;
		font-size: 18px;
		transition: right 0.3s, top 0.3s;
	}

	.intro__teaser__image-mobile {
		max-height: 230px;
		object-fit: contain;
	}
}
