.navbar {
	position: fixed;
	z-index: 999;
  background-color: transparent;
  @include transition(all);
  top: 0;
	.row { 
		height: 80px;
	}
}

.navbar-open .logo {
  @extend %logo-dark;
}

// HIDE & SHOW NAVBAR ON SCROLL
.nav-hide {
  top: -80px;
}
.nav-show {
  background-color: $secondary-color;

	&.color-bg-white {
		background-color: white;
	}
}
.nav-top {
  background-color: transparent !important;
}

// BURGER
.burger, .burger-open {
  cursor: pointer;
  width: 48px;
  height: 48px;
  z-index: 1001;
}
.burger, .burger-open, .navbar a {
  z-index: 1001;
}
.burger-open svg g path {
  stroke: $secondary-color;
}

// MENU
.navbar-nav {
	background-color: $primary-color;
	position: fixed;
  overflow: auto;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 1000;
  align-items: center;
  div {
     height: 100%;
     display: table;
  }
  ul {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-left: 0;
    padding-right: 0;
    @extend %pt-xl;
    @extend %pb-l;
  }
  li {
    display: block;
    margin: 1rem 0;
    @media (min-width: $grid-breakpoint-md) {
      margin: 2rem 0;
    }
    @media (min-width: $grid-breakpoint-xl) {
      margin: 2.5rem 0;
    }
  }
  a, a:hover {
    @extend %text-l;
    font-style: normal;
  }
  a:hover {
    font-style: italic;
    padding-left: 20px;
  }
}

.navbar .navbar-nav {
  height: 0;
  opacity: 0;
  @include transition-custom-2(opacity .3s .15s ease, height 0s .4s ease);
  li {
    opacity: 0;
    @include transform(translateX(-100px));
  }
  li:nth-child(1) {
  @include transition-custom(all .3s .15s ease);
  }
  li:nth-child(2) {
  @include transition-custom(all .3s .1s ease);
  }
  li:nth-child(3) {
  @include transition-custom(all .3s .05s ease);
  }
  li:nth-child(4) {
  @include transition-custom(all .3s 0s ease);
  }
}
.navbar-open .navbar-nav {
  overflow: auto;
  height: 100%;
  opacity: 1;
  @include transition(opacity);
  li {
    opacity: 1;
    @include transform(translateX(0px));
  }
  li:nth-child(1) {
  @include transition-custom(all .3s 0s ease);
  }
  li:nth-child(2) {
  @include transition-custom(all .3s .1s ease);
  }
  li:nth-child(3) {
  @include transition-custom(all .3s .2s ease);
  }
  li:nth-child(4) {
  @include transition-custom(all .3s .3s ease);
  }
}
