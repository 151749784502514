#aniSnake-1,
#aniSnake-2,
#aniSnake-3,
#aniSnake-4,
#aniSnake-5,
#aniSnake-6 {
	position: absolute;
	left: 0;
	svg {
		width: 100%;
		stroke: $primary-color;
	}
}